import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "トイルの削減も、情報漏洩リスクの削減も、両方手に入れる。IAM Identity Centerは欲張りなんだ。",
  "date": "2024-12-03T01:01:22.000Z",
  "slug": "entry/2024/12/03/100122",
  "tags": [],
  "hero": "./thumbnail.png",
  "heroAlt": "トイルの削減も、情報漏洩リスクの削減も、両方手に入れる。IAM Identity Centerは欲張りなんだ。"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* Edit here! */
    }
    <p>{`こちらの記事は `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://qiita.com/advent-calendar/2024/medley"
        }}>{`Medley（メドレー） Advent Calendar 2024`}</a></strong>{` の3日目の記事です。`}</p>
    <h1>{`Who are you?`}</h1>
    <p>{`メドレーで主にSRE活動を行っている人材プラットフォーム本部 第四開発グループの玉井です。`}</p>
    <p>{`最初に簡単にFAQ形式で自己紹介させていただこうと思います。`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`（昨日は何を食べましたか）`}</em>{` ： 麺系ファストフードでは一番好きな小諸そば。`}</li>
      <li parentName="ul"><em parentName="li">{`（好きな本は何ですか）`}</em>{` ： 柳井正さんの自伝書『一勝九敗』は心に残っています。`}</li>
      <li parentName="ul"><em parentName="li">{`（遊びに行くならどこに行きますか）`}</em>{` ： アウトドアが好きなのでキャンプなど。`}</li>
    </ul>
    <p>{`今回は利用しているAWS環境に `}<strong parentName="p">{`IAM Identity Center`}</strong>{` を導入した話をさせていただこうと思います。`}</p>
    <h1>{`セキュリティと管理コストの天秤`}</h1>
    <p>{`メドレーではクレデンシャルな情報は厳重に管理され、平文で外部ツールに保存されることはありません。AWSのIAMユーザのアクセスキーに関しても同じです。
ただ、それでも心配になるのは `}<strong parentName="p">{`PCの中に平文で残る情報`}</strong>{` 。PCも厳重に管理されているのでそうそう漏洩するものではありませんが、それでも可能性はゼロではありません。`}</p>
    <p>{`一時はAssumeRoleを使うことで漏洩時のリスクを減らしました。
最初の認証では何もできない弱い権限で、AssumeRoleで権限の強いロールに変身する方式を導入しました。
この話を聞いて、腕に包帯を巻いた学生服の少年が天狗のお面を被るいらすとが浮かんだあなたはAWSデベロッパーお馴染みのあのブログの敬虔な読者です。`}</p>
    <p><em parentName="p">{`AssumeRole概要図`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "795px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/be69729b8e4a0f7938dfd0875c2b09b8/65c7b/about_assumerole.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAABoklEQVQoz32SSy/EUBTH+0H4CmIhkSAeQbxiQ+xtJezE3spGiERiIV4RRIiFhaV4hAhhmMUkOmai7bSdaaelTPX2vo7cVhiv+eeszj2/c8793ysBAI8EZaKUMsZ+JGOVJ6U/jzVNsx2HUnp9clJQ1R/MN9hxHN0wyiss0yil7wFgoabmbGYaABjB8UZZRcGEfMGrS4v1dbVBEHzydr7ky2Jg7ujyKf3IgXEW5S1rrLoqsbsj2lEq4MThwfrsVME0w6glQpO+36goXdlsu5YfJLQToQZCdAB4tfT55qarjc0AgDMm4K215Z7+AUU3cLQbISuEjBadCdsetwrjOBwJw2FKXQDwbL21o3t7bx8T4aiA11d22lp6UzcpsQxnv/wDxj6u8yjn+nqHZqbm4kqp5L4mTlPJ89TDtfz5UgCE848AIAA8CpBvMskL+e741vd8YViAAlVTS56HMWaMQUXZRdsyTYxDErkjIULy8j3LqZhzSmkllEOx6LwkbzlCIY7hMMyr6ptp+AFiFWHOueO6bjbz5j0jLKyVYid+/9D/x38VvwOoLZfqyjinHgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "AssumeRole概要図",
            "title": "AssumeRole概要図",
            "src": "/static/be69729b8e4a0f7938dfd0875c2b09b8/65c7b/about_assumerole.png",
            "srcSet": ["/static/be69729b8e4a0f7938dfd0875c2b09b8/5a46d/about_assumerole.png 300w", "/static/be69729b8e4a0f7938dfd0875c2b09b8/0a47e/about_assumerole.png 600w", "/static/be69729b8e4a0f7938dfd0875c2b09b8/65c7b/about_assumerole.png 795w"],
            "sizes": "(max-width: 795px) 100vw, 795px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`それでもまだ課題はありました。 `}<strong parentName="p">{`IAMユーザとAssumeRoleの管理コスト`}</strong>{` です。`}</p>
    <p>{`AWSアカウントはサービスごとに複数存在し、さらに本番環境、ステージング環境でも分けてあります。それぞれのAWSアカウントにアクセスできる環境を開発メンバーに提供する必要があります。`}</p>
    <p>{`AssumeRoleは変身する元と変身した先の設定をする必要があり、AWSアカウントを跨ぐ場合はそれぞれのAWSアカウントに設定が必要になります。開発メンバーの異動がある度にあちらこちらのAWSアカウントにその設定をすることが、面倒なトイルになっていました。`}</p>
    <p><strong parentName="p">{`セキュアな環境を維持したまま、管理コストもなるべく下げたい。`}</strong></p>
    <p>{`そこで導入を決めたのが `}<strong parentName="p">{`IAM Identity Center`}</strong>{` でした。`}</p>
    <h1>{`IAM Identity Centerの導入検討`}</h1>
    <p>{`結論を先に申し上げますと、IAM Identity Centerを導入することで `}<strong parentName="p">{`セキュアな環境を維持しながらトイルの削減も実現することができました`}</strong>{` 。
完璧で究極というほどではありませんが、誰もが目を奪われていく理想の環境に一歩近づくことがました。`}</p>
    <p>{`IAM Identity Centerをさらに管理するAWS Control Towerの導入も検討しましたが、既にある環境に導入することの予期せぬ制限がかかることのリスクや、AWS Configが多用されることで思わぬコスト増が発生するとの情報もあり、今回は見送ることにしました。`}</p>
    <h2>{`IAM Identity Centerとは`}</h2>
    <p>{`元は `}<strong parentName="p">{`AWS Single Sign-On （AWS SSO）`}</strong>{` というサービス名でした。
これはIAMユーザとは全く別物のユーザ（これをSSOユーザとします）を作成し、SSOユーザが各AWSアカウントの各IAMロールを持ったユーザに変身することができる機能です。`}</p>
    <p><em parentName="p">{`IAM Identity Center概要図`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8ac0c6a383d3fde2d2c60a8ca58c03ba/d9199/about_iic.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABiUlEQVQoz42RS2/TQBRG8/+3SCCWXVCJFUseooWiWiRNg5QiQZ00GXtsx3aCx495eV53UJyqyQIER3d3db7vjmYEzq2j9OxN8Ozsw/NXH1+cX758/WU6XzRN470H8Ae0ccF88+46fB+Eb4NliDvvYWSMCcbTi89X48n05vbbZDoLxpOr668oxsr4muqGmZabhmllvFJ9L/kh0zk3cs4xzikXSptDDYA31hqtolJ+j/h9Iu4iNnvofiaClGkV3RJS+ye5IqRtWyaktc5aBwCMC85ZQdQiE/FWMWm93yd3lNZNK6U8yoxRKbgxxvunFxqt+k3V3ycC7/o5Yp/m9SKTvWS0Lq21j7K1drVGDyu0XKE4SXGaZZscxbgiFaF2tqRhKoZmx3u7KxKC70hVPcoAUJa7vNziJFujGEV4kxd5UXaU++HWnKibsJstOyatlJK07HDjXvZ/B+D4VQWRhFolWrpDSqmjDANumBNOI/Z75+BXmW3xD0LIv5tPsQN9r6QyWut9EsD/yn/kN9d9cM9O3pADAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IAM Identity Center概要図",
            "title": "IAM Identity Center概要図",
            "src": "/static/8ac0c6a383d3fde2d2c60a8ca58c03ba/d9199/about_iic.png",
            "srcSet": ["/static/8ac0c6a383d3fde2d2c60a8ca58c03ba/5a46d/about_iic.png 300w", "/static/8ac0c6a383d3fde2d2c60a8ca58c03ba/0a47e/about_iic.png 600w", "/static/8ac0c6a383d3fde2d2c60a8ca58c03ba/d9199/about_iic.png 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`IAM Identity Centerを導入した決め手`}</h2>
    <p>{`SSOユーザと権限の管理は一つのAWSアカウントのIAM Identity Centerで管理することができます。
これはAWS Organizationsの機能の上に乗る機能ですが、幸い元々それを導入していたことで、すぐに組み込むことができました（ちなみに現在はIdPを設定することでAWS Organizationsがなくてもこの機能を使うことができます）。`}</p>
    <p>{`つまり、 `}<strong parentName="p">{`複数のAWSアカウントの複数のユーザの複数のロールを一つのIAM Identity Centerで全て管理することができるのです`}</strong>{` 。`}</p>
    <p>{`では、各AWSアカウントで既に作成してしまったIAMポリシーを、IAM Identity Centerで同じ内容で作り直さないといけないかというとそんなこともなく、カスタムロールという設定で、IAMポリシー名だけ定義することで既存のIAMポリシーをそのまま活かすことができます。`}</p>
    <p>{`そして、SSOユーザは恒久的なアクセスキーを持つことができず、最大で12時間の期限付きアクセスキーしか持つことができません。従って、 `}<strong parentName="p">{`仮に万が一アクセスキーが漏洩しても12時間後には使えなくなります`}</strong>{` 。`}</p>
    <p>{`まさに最強で無敵のIAM Identity Centerです。`}</p>
    <h1>{`IAM Identity Centerの導入`}</h1>
    <p>{`ここではIAM Identity Centerの導入の流れを簡単に説明いたします。
一番最初はIAM Identity Centerを有効にすることですが、それは割愛して有効化した後の手順を記載していきます。`}</p>
    <p><em parentName="p">{`IAM Identity Center全体図`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a6a0c690d149ffb595df97f2891c1ff4/d9199/detail_iic.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABt0lEQVQoz01SXW+bMBTN//8nVV/6UlWVGlWTsj1N2bokFaC0XQKYAAEMxti+1/bdIEmb82T53uPzIc8QkbzXYOOPdLNcRatgu454O7gJ1loDJmGs5py3XVXVYIAumGmtEVEp1cT73cvv+v2t2X04BHTOGAPGcM5TxrpO9L2sm8Za+0UGgL7vwzActCaioa43z88/Hx7eFwuZ59Z751xRluyQsoy1bee9B8BBjcszRGzbdrPZOGs9UZ/nq6enl/l8u1iIvKhqX1WWc8oYlSUJQXECyV8my1AIcVbe7/fKGOe9v1jyRGgtAEzJvRBea080boiOF2wnpRzJiGit5Wn6sVyy19c8ikDrE3MaObqCmxScG1VmYysAddMcGdsFQZUkTRwbKUEprRQieH9NHsXHgqeCzrajKAKAU2G/Hh+/390F87k45P3gpfRCUFFQ05DWVFWYxUwWU2ZE7LpuvV5rpYhIZNmP29tvNzd/7u/blPUDyd59kpWivqf6yI+HS2YpZZqmxhjrnO66LAyTICjftkPbOn9d4ldsRHe2rbU2xiit1TBoY9A5dA6sHW8v/8l7Oj3yefhP/gdZOnAK3F2roAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IAM Identity Center全体図",
            "title": "IAM Identity Center全体図",
            "src": "/static/a6a0c690d149ffb595df97f2891c1ff4/d9199/detail_iic.png",
            "srcSet": ["/static/a6a0c690d149ffb595df97f2891c1ff4/5a46d/detail_iic.png 300w", "/static/a6a0c690d149ffb595df97f2891c1ff4/0a47e/detail_iic.png 600w", "/static/a6a0c690d149ffb595df97f2891c1ff4/d9199/detail_iic.png 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`SSOユーザの作成`}</h2>
    <p>{`SSOユーザは前述した通りIAMユーザとは全く別物なので、新たに全員分作成する必要があります。
最低限必要なのは名前とメールアドレスのみです。
作成画面に任意項目で住所や電話番号などあったりするのですが、システム的には全く関係ないようです。IAM Identity CenterをIdPとして使うときのための設定でしょうか。`}</p>
    <p><em parentName="p">{`SSOユーザ作成画面`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/383541a2331cc83dbf161c9c3149b17f/d9c39/create_user.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "98.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAIBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAe3c0ATkNYxf/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAECMP/aAAgBAQABBQLB0f/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CH//EABgQAAMBAQAAAAAAAAAAAAAAAAABERAh/9oACAEBAAE/IYQhZlHjE5w//9oADAMBAAIAAwAAABCjwD//xAAXEQEAAwAAAAAAAAAAAAAAAAABABAh/9oACAEDAQE/ELDJ/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQAQEf/aAAgBAgEBPxC1dn//xAAcEAADAAIDAQAAAAAAAAAAAAAAAREhYTFBUXH/2gAIAQEAAT8QhJJ+iw4g0HgcpR6HT6Uc0UbP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "SSOユーザ作成画面",
            "title": "SSOユーザ作成画面",
            "src": "/static/383541a2331cc83dbf161c9c3149b17f/e5166/create_user.jpg",
            "srcSet": ["/static/383541a2331cc83dbf161c9c3149b17f/f93b5/create_user.jpg 300w", "/static/383541a2331cc83dbf161c9c3149b17f/b4294/create_user.jpg 600w", "/static/383541a2331cc83dbf161c9c3149b17f/e5166/create_user.jpg 1200w", "/static/383541a2331cc83dbf161c9c3149b17f/d9c39/create_user.jpg 1800w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`SSOグループの作成`}</h2>
    <p>{`SSOグループとは、SSOユーザと後述する許可セットをまとめるためのものです。
新しいSSOユーザが増えた場合は、該当のグループに追加してあげるだけでOKです。`}</p>
    <p><em parentName="p">{`SSOグループ作成画面`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/95109a0cc1318607b87059fd216525f4/d9c39/create_group.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe7c0oP/xAAVEAEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAQABBQIr/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGBAAAwEBAAAAAAAAAAAAAAAAABFhARD/2gAIAQEAAT8hwIQrxU//2gAMAwEAAgADAAAAEHMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhAAAwEBAQEAAAAAAAAAAAAAAAERITFxof/aAAgBAQABPxBSVj0gkw1rs6X6qex//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "SSOグループ作成画面",
            "title": "SSOグループ作成画面",
            "src": "/static/95109a0cc1318607b87059fd216525f4/e5166/create_group.jpg",
            "srcSet": ["/static/95109a0cc1318607b87059fd216525f4/f93b5/create_group.jpg 300w", "/static/95109a0cc1318607b87059fd216525f4/b4294/create_group.jpg 600w", "/static/95109a0cc1318607b87059fd216525f4/e5166/create_group.jpg 1200w", "/static/95109a0cc1318607b87059fd216525f4/d9c39/create_group.jpg 1800w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`許可セットの作成`}</h2>
    <p>{`許可セットは、IAMロールの素になるものです。
ここで設定したポリシーが各AWSアカウントにIAMロール、IAMポリシーとしてプロビジョニングされます。`}</p>
    <p>{`プロビジョニング先のAWSアカウントに既にあるIAMポリシーを使いたい場合は、カスタマーマネージドポリシーでその名前を設定すれば割り当てることができます。
名前が間違っていて存在しないIAMポリシーを設定してしまった場合は、プロビジョニング時にエラーになります。`}</p>
    <p><em parentName="p">{`許可セット作成画面`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1d991b4a460f5320fb37bdae32e27277/d9c39/create_permisisonset.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "103%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAA//aAAwDAQACEAMQAAAB7cyBRAaxCn//xAAYEAACAwAAAAAAAAAAAAAAAAAAAQIRMP/aAAgBAQABBQLByo//xAAXEQEAAwAAAAAAAAAAAAAAAAABAAIQ/9oACAEDAQE/AdKk/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECEBH/2gAIAQIBAT8Btzen/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwIf/8QAGxAAAgMAAwAAAAAAAAAAAAAAABEBECExQfD/2gAIAQEAAT8hWEe2pMMrjuRpJn//2gAMAwEAAgADAAAAEHDIPf/EABcRAQADAAAAAAAAAAAAAAAAAAEAEBH/2gAIAQMBAT8QtAOT/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAQ/9oACAECAQE/ENKgb//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESFxMUFhkaH/2gAIAQEAAT8QaZPepjYMAvb7IqILzZWoNcRLKtNQOX0nNnlc/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "許可セット作成画面",
            "title": "許可セット作成画面",
            "src": "/static/1d991b4a460f5320fb37bdae32e27277/e5166/create_permisisonset.jpg",
            "srcSet": ["/static/1d991b4a460f5320fb37bdae32e27277/f93b5/create_permisisonset.jpg 300w", "/static/1d991b4a460f5320fb37bdae32e27277/b4294/create_permisisonset.jpg 600w", "/static/1d991b4a460f5320fb37bdae32e27277/e5166/create_permisisonset.jpg 1200w", "/static/1d991b4a460f5320fb37bdae32e27277/d9c39/create_permisisonset.jpg 1800w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`プロビジョニング`}</h2>
    <p>{`"SSOユーザまたはグループ" と "許可セット" を適用したいAWSアカウントにプロビジョニングすることで、対象SSOユーザで該当AWSアカウントにログイン可能になります。`}</p>
    <p><em parentName="p">{`プロビジョニング概要図`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e5415d97cd61a1e5e4ea51c9cab8e36e/1cfc2/about_provisioning.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAArklEQVQY042PzQqCUBSEff/3cOcDuPInFe9CBVEXUptUctGi8HY99+ecW0QREUF9zG5mYMYhImMMIqK5Q0TXv3Es4r4oSt9vg2Asy5VzbQxpjQAo5UMkpVKK80WIFRE5vyiln+U+DCPXZZ435DnLsk2awjw3YbhlbMdYlyRjVRmQcSfiVpzOfDm0x6nXhhxLNNR1HUVdmk5NowDIfuE1FRFhFQCrtdZ5dz5yPz/fAL4VGv2ErC/LAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "プロビジョニング概要図",
            "title": "プロビジョニング概要図",
            "src": "/static/e5415d97cd61a1e5e4ea51c9cab8e36e/1cfc2/about_provisioning.png",
            "srcSet": ["/static/e5415d97cd61a1e5e4ea51c9cab8e36e/5a46d/about_provisioning.png 300w", "/static/e5415d97cd61a1e5e4ea51c9cab8e36e/0a47e/about_provisioning.png 600w", "/static/e5415d97cd61a1e5e4ea51c9cab8e36e/1cfc2/about_provisioning.png 900w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`SSOアクセスポータル画面`}</h2>
    <p>{`SSOユーザでログインするAWSアクセスポータル画面に、AWSアカウントへのリンクの一覧が表示されます。
このリンクから各AWSアカウントに、任意のロールでログインすることができます。便利ですね！`}</p>
    <p><em parentName="p">{`AWSアクセスポータル画面`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/382aad5b8c5f4216a705a53aca356879/72e01/accessportal.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAddlEjD/xAAYEAEAAwEAAAAAAAAAAAAAAAAAAxITAv/aAAgBAQABBQLKNjGz4XXWf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAACMTKREP/aAAgBAQAGPwKi4UXCi4QRz//EABsQAAIBBQAAAAAAAAAAAAAAAAARIRAxQdHw/9oACAEBAAE/IZqACwXbHTJ4P//aAAwDAQACAAMAAAAQSA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAfEAEAAQIHAQAAAAAAAAAAAAABABEhEEFRcaGxwfD/2gAIAQEAAT8QqV5eiLnnhYAbCFI+OMAXHvP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "AWSアクセスポータル画面",
            "title": "AWSアクセスポータル画面",
            "src": "/static/382aad5b8c5f4216a705a53aca356879/72e01/accessportal.jpg",
            "srcSet": ["/static/382aad5b8c5f4216a705a53aca356879/f93b5/accessportal.jpg 300w", "/static/382aad5b8c5f4216a705a53aca356879/b4294/accessportal.jpg 600w", "/static/382aad5b8c5f4216a705a53aca356879/72e01/accessportal.jpg 1024w"],
            "sizes": "(max-width: 1024px) 100vw, 1024px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`一時的なアクセスキーはCLIでも取得できますが、この画面からも `}<strong parentName="p">{`アクセスキー`}</strong>{` のリンクをクリックすると一覧画面が表示されます。`}</p>
    <p><em parentName="p">{`AWSアクセスポータル アクセスキー確認画面`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/03eef24ab5575fd4b27e35bd2dbc44d9/72e01/accessportal_key.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3LAB/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQESFh8P/aAAgBAQABPyEWoqxdiP/aAAwDAQACAAMAAAAQU8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhUXGBkf/aAAgBAQABPxBDR7CVgV2UaIqgV8uHkZn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "AWSアクセスポータル アクセスキー確認画面",
            "title": "AWSアクセスポータル アクセスキー確認画面",
            "src": "/static/03eef24ab5575fd4b27e35bd2dbc44d9/72e01/accessportal_key.jpg",
            "srcSet": ["/static/03eef24ab5575fd4b27e35bd2dbc44d9/f93b5/accessportal_key.jpg 300w", "/static/03eef24ab5575fd4b27e35bd2dbc44d9/b4294/accessportal_key.jpg 600w", "/static/03eef24ab5575fd4b27e35bd2dbc44d9/72e01/accessportal_key.jpg 1024w"],
            "sizes": "(max-width: 1024px) 100vw, 1024px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`導入後に対応したこと`}</h1>
    <p>{`これまでの手順でマネジメントコンソールのでログインはできるようになっていますが、それ以外のツール等で利用するためにしたことをここでは説明します。`}</p>
    <h2>{`~/.aws/configの修正`}</h2>
    <p>{`ターミナルでAWS CLIを利用するために、これまでアクセスキーを直接指定していたのを、SSOユーザ対応の記述に変更する必要があります。
アクセスキーなどのクレデンシャルな情報の記載が不要になり、この内容が漏洩してもダメージが少ないです。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "toml"
    }}><pre parentName="div" {...{
        "className": "language-toml"
      }}><code parentName="pre" {...{
          "className": "language-toml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token table class-name"
          }}>{`default`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token key property"
          }}>{`sso_session`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` my-sso
`}<span parentName="code" {...{
            "className": "token key property"
          }}>{`sso_account_id`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`999999999999`}</span>{`
`}<span parentName="code" {...{
            "className": "token key property"
          }}>{`sso_role_name`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` xxxxxxxxxxxx

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`sso-session my-sso`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token key property"
          }}>{`sso_start_url`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` https://xxxxxxxxxxxx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`awsapps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`com/start
`}<span parentName="code" {...{
            "className": "token key property"
          }}>{`sso_registration_scopes`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` sso:account:access`}</code></pre></div>
    <p>{`sso_sessionの設定が古いaws_sdkだと対応していなかったりしたので、最新のaws_sdkを使うように `}<a parentName="p" {...{
        "href": "https://mise.jdx.dev/"
      }}>{`mise`}</a>{`  (開発ツール管理用ツール)に設定しました。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "toml"
    }}><pre parentName="div" {...{
        "className": "language-toml"
      }}><code parentName="pre" {...{
          "className": "language-toml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token table class-name"
          }}>{`tools`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token key property"
          }}>{`awscli`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"latest"`}</span></code></pre></div>
    <p>{`この設定をした状態で `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aws sso login`}</code>{` を実行すると認証画面のブラウザが自動で立ち上がり、ブラウザでの認証完了後にターミナルでCLIが使えるようになります。`}</p>
    <p><em parentName="p">{`ターミナルでsso login`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9e00e120a65a8fdda26c4669d10ea20e/af3b8/sso_login.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.333333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABy5AD/8QAFRABAQAAAAAAAAAAAAAAAAAAARD/2gAIAQEAAQUCb//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABYQAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAQABPyFnSsp//9oADAMBAAIAAwAAABBwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABgQAAMBAQAAAAAAAAAAAAAAAAABEVFh/9oACAEBAAE/EEoTOzK0/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ターミナルでsso login",
            "title": "ターミナルでsso login",
            "src": "/static/9e00e120a65a8fdda26c4669d10ea20e/e5166/sso_login.jpg",
            "srcSet": ["/static/9e00e120a65a8fdda26c4669d10ea20e/f93b5/sso_login.jpg 300w", "/static/9e00e120a65a8fdda26c4669d10ea20e/b4294/sso_login.jpg 600w", "/static/9e00e120a65a8fdda26c4669d10ea20e/e5166/sso_login.jpg 1200w", "/static/9e00e120a65a8fdda26c4669d10ea20e/d9c39/sso_login.jpg 1800w", "/static/9e00e120a65a8fdda26c4669d10ea20e/af3b8/sso_login.jpg 1890w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`ブラウザで認証`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/045ce19e00efcf991ef4a3222c3e32f1/61946/sso_auth.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABp0lEQVQ4y41Sy27bMBD01+XUr+jH5Et6KtBLeinQ9lAEDVAgyKWXBLH1tFFbFikuHyIlt5qCG1lOGzsJgdFS+xgOlzvTxuAYSGu2ijSElKhlA4oxrUH6eE3E7DnC1rdI0gQfLj7h8vsVpKyhrZ0OO0p4Msh+CyUryPQKqrgBkWSfPlETuWb7zUkYA2NoLHg+9x+F1jlYa59YE+EcW+tG/yOYMeeJwsUiQV4USJIUWZYjzwukWYaiLJFlGfvm8wVWqxVub+9wfz/n/7Jcouu6A2H8xJMaKSGE5BdVSoFIw/B1H3pm9i9MxIg5Ec45hBBY5ahQ85VWQqDIc0ZUQ2Nxtd2irgWqassE3nsmiTGtDbelbT2LelAYe7HZQF18RL6ucZcusalqJmkUofUep9cwYVLIhNUW9vNXrMljKSxC16Pve4Suw263wzAME5hmOFDtaQ+ERAh9j5dWbHyjFNaVwK6VCF/eonr/BuW7M/TX57DhD4jUo7GJD2D34+GOjkfMlY2C0Qqm/IEmuYSYf4P59RPaulcO9n+Dy/kR7W9oP8CEAeT6abD/AteA3hgcnJJvAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ブラウザで認証",
            "title": "ブラウザで認証",
            "src": "/static/045ce19e00efcf991ef4a3222c3e32f1/c1b63/sso_auth.png",
            "srcSet": ["/static/045ce19e00efcf991ef4a3222c3e32f1/5a46d/sso_auth.png 300w", "/static/045ce19e00efcf991ef4a3222c3e32f1/0a47e/sso_auth.png 600w", "/static/045ce19e00efcf991ef4a3222c3e32f1/c1b63/sso_auth.png 1200w", "/static/045ce19e00efcf991ef4a3222c3e32f1/61946/sso_auth.png 1546w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`開発環境へのアクセスキーの渡し方`}</h2>
    <p>{`開発環境においてアクセスキーの設定が必要な箇所があり、それを毎日ポータル画面からコピペで更新してくださいというのは面倒すぎるので、毎日1回最初にシェルを実行してもらうようにしました（これも面倒なのでこれなしでどうにかできれば良かったのですが・・・無念）。`}</p>
    <p>{`この実行にはSSOの認証情報をシェル内の処理で利用するため `}<a parentName="p" {...{
        "href": "https://github.com/linaro-its/aws2-wrap"
      }}>{`aws2-wrap`}</a>{` を利用し、.envファイルの更新には `}<a parentName="p" {...{
        "href": "https://github.com/theskumar/python-dotenv"
      }}>{`python-dotenv`}</a>{` を利用しました。`}</p>
    <p>{`これもmiseで定義することで、全開発メンバーにもれなく対応できました。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# 一部抜粋`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span>{`aws2-wrap --profile `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"`}<span parentName="span" {...{
                "className": "token variable"
              }}>{`$profile`}</span>{`"`}</span>{` --export`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{`
    aws sso login --profile`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$profile`}</span>{`"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`eval`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span>{`aws2-wrap --profile `}<span parentName="span" {...{
                "className": "token string"
              }}>{`"`}<span parentName="span" {...{
                  "className": "token variable"
                }}>{`$profile`}</span>{`"`}</span>{` --export`}<span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fi`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# 一部抜粋`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` dotenv `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` set_key

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`update_env_file`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`env_file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` key_value_pairs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` key `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` key_value_pairs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            value `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` key_value_pairs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
            set_key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`env_file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` quote_mode`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'never'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`except`}</span>{` Exception `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2>{`Terraform, Packerの対応`}</h2>
    <p>{`最新のTerraform、Packerではaws ssoに対応しているので、ssoのprofileをそのまま使うことができます（AssumeRoleには対応していなかったので、それを対応させた時は大変でした・・・）。`}</p>
    <p>{`Terraformは環境依存をなくすため実行環境をコンテナ化しコンテナ内で実行しているのですが、"ログイン時にSSOセッションの存在有無の確認" → "なければSSOログイン" をさせることで、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aws sso login`}</code>{` の事前実行を意識しなくても良いようにしました。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# 一部抜粋`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function-name function"
          }}>{`get_caller_identity`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`set`}</span>{` +eu

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token for-or-select variable"
          }}>{`i`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`command`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"aws sts get-caller-identity --profile `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$1`}</span>{`"`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$?`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{`
      `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`break`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
      `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Not logged in"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`command`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"aws sso login --profile `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$1`}</span>{`"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fi`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`done`}</span>{`

	`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`set`}</span>{` -eu
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`最新のPackerはaws ssoに対応していると前述しましたが、なぜだか環境により `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sso-session`}</code>{` が効かずにエラーになる場合がありました。その場合はレガシーな記述方法で回避できます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "toml"
    }}><pre parentName="div" {...{
        "className": "language-toml"
      }}><code parentName="pre" {...{
          "className": "language-toml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`profile packer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token key property"
          }}>{`credential_process`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` aws --profile default configure export-credentials --format process
`}<span parentName="code" {...{
            "className": "token key property"
          }}>{`region`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` ap-northeast`}<span parentName="code" {...{
            "className": "token number"
          }}>{`-1`}</span>{`
`}<span parentName="code" {...{
            "className": "token key property"
          }}>{`output`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`=`}</span>{` json`}</code></pre></div>
    <h1>{`対応して良かったこと`}</h1>
    <h2>{`管理者目線で良かったこと`}</h2>
    <p>{`管理者目線では、AWSのユーザ管理が大分楽になりました。`}</p>
    <p>{`これまで開発メンバーが増えた際には、複数のAWSアカウントに対しそれぞれに、IAMユーザの作成、アクセスキーの生成をし、その後初期ログインパスワードとアクセスキーをメンバーに配布しなければならなかったため、作業が大分煩雑でした。`}</p>
    <p>{`IAM Identity Center導入後は、 `}<strong parentName="p">{`1つのAWSアカウントでSSOユーザを作成するだけです`}</strong>{` 。招待メールも勝手に送られます。`}</p>
    <p>{`また、与えている権限の管理も楽になりました。`}</p>
    <p><strong parentName="p">{`IAM Identity Centerの画面を見れば、誰がどのAWSアカウントにどんな権限を持っているか全てわかりますし、設定するのもそこだけなので`}</strong>{` 、いろんなAWSアカウントを出入りして確認・設定する必要がなくなりました。`}</p>
    <h2>{`利用者目線で良かったこと`}</h2>
    <p>{`利用者目線では、各AWSアカウントへのログインがポータル画面のリンクから行くことができるので、 `}<strong parentName="p">{`AWSアカウントごとにログインの作業が必要なくなったのも楽ですし、各AWSアカウント毎にログイン情報、MFA情報を保持しなくても良くなったのも管理が気楽になりました`}</strong>{` 。`}</p>
    <h1>{`対応して困ったこと`}</h1>
    <h2>{`12時間の壁`}</h2>
    <p>{`SSOユーザのセッション時間の上限が12時間のため、朝9時にログインすると夜9時にセッションが切れてしまいます。そのことを忘れて12時間後に急に開発環境でエラーが出始めて「なんじゃこりゃあああ！」とたまに驚かされます（12時間以上働くことがほとんどないのでめったに遭遇しませんが、だからこそ忘れます）。`}</p>
    <p>{`深夜メンテの際にそれが起きるとトラブルになりますので、その際は作業前に必ずログインし直すようにしています。`}</p>
    <p>{`個人的にはセッション時間の上限の時間もう少し上げて欲しいです。`}</p>
    <h2>{`利用できないサードパーティーツールの存在`}</h2>
    <p>{`AWSにアクセスするためのサードパーティーツールは様々ありますが、`}<strong parentName="p">{`AWS_ACCESS_KEY_ID`}</strong>{` 、 `}<strong parentName="p">{`AWS_SECRET_ACCESS_KEY`}</strong>{` の2項目しか設定できないものがあります。`}</p>
    <p>{`SSOユーザでログインするには、それらに加え `}<strong parentName="p">{`AWS_SESSION_TOKEN`}</strong>{` の値が必要であるため、設定できないツールはSSOユーザでは利用できないことになります。`}</p>
    <p>{`最近ではSSOユーザでも利用できるようにアップグレードされているツールも増えてきていますので、今まで使っていたツールが使えなくなった場合は代替のツールを使いましょう。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`これは絶対嘘ではなくて、IAM Identity Centerを導入することで、トイルの削減も、情報漏洩リスクの削減も、両方実現することができました。`}</p>
    <p>{`もちろん私たちの挑戦はこれで終わりではなく、サービスの品質を上げるため、開発メンバーが開発に集中できる環境を作るため、 `}<strong parentName="p">{`俺たちの戦いはまだ始まったばかり`}</strong>{` 、です。`}</p>
    <p>{`そして、明日12月4日は @shigerisa さんが何やらもっと面白い記事を書いてくれているようです！お楽しみに！`}</p>
    <h1>{`We're hiring!`}</h1>
    <p>{`メドレーでは各種エンジニアを絶賛募集中です！`}</p>
    <p>{`カジュアル面談いつでもWelcomeですので、お話しだけでも聞いてみたい、むしろ私の推しの話を聞いて欲しい、などなんでもかまいませんので、お気軽にお問い合わせください！`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      